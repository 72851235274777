import { Box } from '@material-ui/core';
import React, { forwardRef } from 'react';
import BackCard from './helpers/BackCard';
import CardFront from './helpers/CardFront';
import styles from './style';

function Card(props, ref) {
  const classes = styles();
  return (
    <Box className={classes.cardWrapper} ref={ref}>
      <CardFront
        staffInfo={props.staffData}
        postOptions={props.postOptions}
        categoryOptions={props.categoryOptions}
      />
      <BackCard
        staffInfo={props.staffData}
        postOptions={props.postOptions}
      />
    </Box>
  )
}

export default forwardRef(Card);
