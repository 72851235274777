import { Box, Container, Grid, IconButton, Link, Paper, Tooltip, Typography } from "@material-ui/core";
import { Add as AddIcon, Close as CloseIcon, Create as CreateIcon, Delete as DeleteIcon, Help, Print, Publish as PublishIcon } from '@material-ui/icons';
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { FilePreviewerThumbnail } from 'react-file-previewer';
import ReactToPrint from 'react-to-print';
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import EmptyContainer from "../../../components/empty-container/EmptyContainer";
import Spinner from "../../../components/loader/Loader";
import CustomModal from "../../../components/modal/CustomModal";
import { AppMisc } from "../../../misc/appMisc";
import { AppUtils } from "../../../utils/appUtils";
import { AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG, ALL_FILES_SIZE_EXCEED, ERROR, FILE_SIZE_EXCEED, ID, NO_RECORDS_FOUND, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants/index";
import { DateUtils } from "../../../utils/dateUtils";
import AddAddressDetails from "../components/add-address-details/AddAddressDetails";
import AddAppointedServiceDetails from "../components/add-appointed-service/AddAppointedServiceDetails";
import AddAwardAndTestimonialDetails from "../components/add-award-and-testimonial-details/AddAwardAndTestimonialDetails";
import AddCurrentServiceDetails from "../components/add-current-service-details/AddCurrentServiceDetails";
import AddDepartmentalPunishmentDetails from "../components/add-departmental-punishment-details/AddDepartmentalPunishmentDetails";
import AddEducationalDetails from "../components/add-educational-details/AddEducationalDetails";
import AddFamilyDetails from "../components/add-family-details/AddFamilyDetails";
import AddStaffLeaveDetailsModal from "../components/add-leave-details/AddStaffLeaveDetailsModal";
import AddStaffMedicalExpensesDetails from "../components/add-medical-expenses-details/AddStaffMedicalExpensesDetails";
import AddOtherDetails from "../components/add-others-details/AddOtherDetails";
import AddRetirementDetails from "../components/add-retirement-details/AddRetirementDetails";
import Card from "../components/card/Card";
import AddPersonalDetails from "../components/staff/add-personal-details/AddPersonalDetailsModal";
import AwardAndTestimonialDetail from "./helpers/award-and-testimonial-detail/AwardAndTestimonialDetail";
import DepartmentalPunishmentDetails from "./helpers/departmental-punishment-details/DepartmentalPunishmentDetails";
import EducationalDetails from "./helpers/educational-details/EducationalDetails";
import AdditionalServiceLeaveDetails from "./helpers/leave-type-details/AdditionalServiceLeaveDetails";
import CommonAbnormalLeaveDetails from "./helpers/leave-type-details/CommonAbnormalLeaveDetails";
import DeathRitualLeaveDetails from "./helpers/leave-type-details/DeathRitualLeaveDetails";
import FestivalLeaveDetails from "./helpers/leave-type-details/FestivalLeaveDetails";
import HomeLeaveDetails from "./helpers/leave-type-details/HomeLeaveDetails";
import ImmediateLeaveDetails from "./helpers/leave-type-details/ImmediateLeaveDetails";
import InsteadLeaveDetails from "./helpers/leave-type-details/InsteadLeaveDetails";
import PregnancyCareLeaveDetails from "./helpers/leave-type-details/PregnancyCareLeaveDetails";
import PregnancyLeaveDetails from "./helpers/leave-type-details/PregnancyLeaveDetails";
import ServingInternationalOrganizationLeaveDetails from "./helpers/leave-type-details/ServingInternationalOrganizationLeaveDetails";
import SickLeaveDetails from "./helpers/leave-type-details/SickLeaveDetails";
import StudyAbnormalLeaveDetails from "./helpers/leave-type-details/StudyAbnormalLeaveDetails";
import StudyLeaveDetails from "./helpers/leave-type-details/StudyLeaveDetails";
import UnpaidPregnancyLeaveDetails from "./helpers/leave-type-details/UnpaidPregnancyLeaveDetails";
import OtherDetails from "./helpers/other-details/OtherDetails";
import StaffAddressDetails from "./helpers/staff-address-details/StaffAddressDetails";
import StaffAppointedServiceDetails from "./helpers/staff-appointed-service-details/StaffAppointedServiceDetails";
import StaffCurrentServiceDetails from "./helpers/staff-current-service-details/StaffCurrentServiceDetails";
import StaffFamilyDetails from "./helpers/staff-family-details/StaffFamilyDetails";
import StaffMedicalExpensesDetails from "./helpers/staff-medical-expenses-details/StaffMedicalExpensesDetails";
import StaffPersonalDetails from "./helpers/staff-personal-details/StaffPersonalDetails";
import StaffRetirementDetails from "./helpers/staff-retirement-details/StaffRetirementDetails";
import styles from "./style";

function StaffDetails(props, ref) {
  const classes = styles();
  const [staffData, setStaffData] = useState({});
  const [staffDataLoading, setStaffDataLoading] = useState(true);
  const [editPersonalDetails, setEditPersonalDetails] = useState(false);
  const [editFamilyDetails, setEditFamilyDetails] = useState(false);
  const [editAddressDetails, setEditAddressDetails] = useState(false);
  const [editAppointedServiceDetails, setEditAppointedServiceDetails] = useState(false);
  const [editCurrentServiceDetails, setEditCurrentServiceDetails] = useState(false);
  const [editOtherDetails, setEditOtherDetails] = useState(false);
  const [editEducationalDetails, setEditEducationalDetails] = useState(false);
  const [addStaffLeave, setAddStaffLeave] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState();
  const [leaveDocumentToDelete, setLeaveDocumentToDelete] = useState();
  const [medicalExpensesDocumentToDelete, setMedicalExpensesDocumentToDelete] = useState();
  const [departmentalPunishmentDocumentToDelete, setDepartmentalPunishmentDocumentToDelete] = useState();
  const [editRetirementDetails, setEditRetirementDetails] = useState(false);
  const [editAwardAndTestimonialDetails, setEditAwardAndTestimonialDetails] = useState(false);
  const [showDeleteDocumentConfirmationModal, setShowDeleteDocumentConfirmationModal] = useState(false);
  const [showDeleteLeaveDocumentConfirmationModal, setShowDeleteLeaveDocumentConfirmationModal] = useState(false);
  const [showDeleteMedicalExpensesDocumentConfirmationModal, setShowDeleteMedicalExpensesDocumentConfirmationModal] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [allSubDepartmentList, setAllSubDepartmentList] = useState([]);
  const [files, setFiles] = useState([]);
  const [leaveFiles, setLeaveFiles] = useState([]);
  const [departmentalPunishmentFiles, setDepartmentalPunishmentFiles] = useState([]);
  const [medicalExpensesFiles, setMedicalExpensesFiles] = useState(null);
  const [educationalQualificationList, setEducationalQualificationList] = useState([]);
  const [staffLeaveDetails, setStaffLeaveDetails] = useState([]);
  const [staffImmediateLeaveDetails, setStaffImmediateLeaveDetails] = useState([]);
  const [staffFestivalLeaveDetails, setStaffFestivalLeaveDetails] = useState([]);
  const [staffHomeLeaveDetails, setStaffHomeLeaveDetails] = useState([]);
  const [staffSickLeaveDetails, setStaffSickLeaveDetails] = useState([]);
  const [staffPregnancyLeaveDetails, setStaffPregnancyLeaveDetails] = useState([]);
  const [staffUnpaidPregnancyLeaveDetails, setStaffUnpaidPregnancyLeaveDetails] = useState([]);
  const [staffPregnancyCareLeaveDetails, setStaffPregnancyCareLeaveDetails] = useState([]);
  const [staffDeathRitualLeaveDetails, setStaffDeathRitualLeaveDetails] = useState([]);
  const [staffStudyLeaveDetails, setStaffStudyLeaveDetails] = useState([]);
  const [staffCommonAbnormalLeaveDetails, setStaffCommonAbnormalLeaveDetails] = useState([]);
  const [staffStudyAbnormalLeaveDetails, setStaffStudyAbnormalLeaveDetails] = useState([]);
  const [staffInsteadLeaveDetails, setStaffInsteadLeaveDetails] = useState([]);
  const [staffAdditionalServiceLeaveDetails, setStaffAdditionalServiceLeaveDetails] = useState([]);
  const [showDeleteStaffLeaveConfirmationModal, setShowDeleteStaffLeaveConfirmationModal] = useState(false);
  const [staffServingInternationalOrganizationLeaveDetails, setStaffServingInternationalOrganizationLeaveDetails] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [postOptions, setPostOptions] = useState([]);
  const [medicalExpensesDetails, setMedicalExpensesDetails] = useState([]);
  const [modalDefaultValue, setModalDefaultValue] = useState();
  const [editStaffMedicalExpenses, setEditStaffMedicalExpenses] = useState(false);
  const [editDepartmentalPunishmentDetails, setEditDepartmentalPunishmentDetails] = useState(false);
  const [showDeleteStaffMedicalExpensesConfirmationModal, setShowDeleteStaffMedicalExpensesConfirmationModal] = useState(false);
  const [showDeleteDepartmentalPunishmentDocumentConfirmationModal, setShowDeleteDepartmentalPunishmentDocumentConfirmationModal] = useState(false);
  const [showDeleteDepartmentalPunishmentConfirmationModal, setShowDeleteDepartmentalPunishmentConfirmationModal] = useState(false);
  const [medicalExpensesDetailsDefaultValue, setMedicalExpensesDetailsDefaultValue] = useState();
  const [departmentalPunishmentDetailsDefaultValue, setDepartmentalPunishmentDetailsDefaultValue] = useState();
  const [departmentalPunishmentDetails, setDepartmentalPunishmentDetails] = useState([]);
  const printComponentRef = useRef();
  const printCardRef = useRef();
  const staffId = AppUtils.getUrlParam(ID) || props.id;
  const [leaveId, setLeaveId] = useState();
  const [medicalExpensesId, setMedicalExpensesId] = useState();
  const [departmentalPunishmentId, setDepartmentalPunishmentId] = useState();

  function handleLeaveFileChange(e) {
    let files = e.target.files;
    let individualFileSizeValid = true;
    let fileSize = 0;
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        fileSize += files[i].size;
        if (files[i].size > 10 * 1024 * 1024) {
          individualFileSizeValid = false;
          AddAlertMessage({ type: ERROR, message: FILE_SIZE_EXCEED });
          return false;
        }
      }
      let allFilesSizeValid = fileSize < (30 * 1024 * 1024);
      if (individualFileSizeValid && allFilesSizeValid) {
        setLeaveFiles(e.target.files);
      } else {
        AddAlertMessage({ type: ERROR, message: ALL_FILES_SIZE_EXCEED });
      }
    }
  }

  function handleMedicalExpensesFileChange(e) {
    e.preventDefault();
    let files = e.target.files;
    let individualFileSizeValid = true;
    let fileSize = 0;
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        fileSize += files[i].size;
        if (files[i].size > 10 * 1024 * 1024) {
          individualFileSizeValid = false;
          AddAlertMessage({ type: ERROR, message: FILE_SIZE_EXCEED });
          return false;
        }
      }
      let allFilesSizeValid = fileSize < (30 * 1024 * 1024);
      if (individualFileSizeValid && allFilesSizeValid) {
        setMedicalExpensesFiles(e.target.files);
      } else {
        AddAlertMessage({ type: ERROR, message: ALL_FILES_SIZE_EXCEED });
      }
    }
  }

  function handleDepartmentalPunishmentFileChange(e) {
    e.preventDefault();
    let files = e.target.files;
    let individualFileSizeValid = true;
    let fileSize = 0;
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        fileSize += files[i].size;
        if (files[i].size > 10 * 1024 * 1024) {
          individualFileSizeValid = false;
          AddAlertMessage({ type: ERROR, message: FILE_SIZE_EXCEED });
          return false;
        }
      }
      let allFilesSizeValid = fileSize < (30 * 1024 * 1024);
      if (individualFileSizeValid && allFilesSizeValid) {
        setDepartmentalPunishmentFiles(e.target.files);
      } else {
        AddAlertMessage({ type: ERROR, message: ALL_FILES_SIZE_EXCEED });
      }
    }
  }

  const handleLeaveDocumentDelete = (document, id) => {
    document.id = id;
    setShowDeleteLeaveDocumentConfirmationModal(true);
    setLeaveDocumentToDelete(document);
    setLeaveId('');
  }

  const handleMedicalExpensesDocumentDelete = (document, id) => {
    document.id = id;
    setShowDeleteMedicalExpensesDocumentConfirmationModal(true);
    setMedicalExpensesDocumentToDelete(document);
    setMedicalExpensesId('');
  }

  const handleDepartmentalPunishmentDocumentDelete = (document, id) => {
    document.id = id;
    setShowDeleteDepartmentalPunishmentDocumentConfirmationModal(true);
    setDepartmentalPunishmentDocumentToDelete(document);
    setDepartmentalPunishmentId('');
  }

  const setLeaveRowId = id => {
    setLeaveId(id);
  }

  const getLeaveRowDeleteId = id => {
    setLeaveRowId(id);
    setShowDeleteStaffLeaveConfirmationModal(true);
  }

  const setMedicalExpensesRowId = id => {
    setMedicalExpensesId(id);
  }

  const getMedicalExpensesRowDeleteId = (id) => {
    setMedicalExpensesRowId(id);
    setShowDeleteStaffMedicalExpensesConfirmationModal(true);
  }

  const setDepartmentalPunishmentRowId = id => {
    setDepartmentalPunishmentId(id);
  }

  const getDepartmentalPunishmentRowDeleteId = (id) => {
    setDepartmentalPunishmentRowId(id);
    setShowDeleteDepartmentalPunishmentConfirmationModal(true);
  }

  const removeLeaveFile = (document) => {
    setShowDeleteLeaveDocumentConfirmationModal(false);
    let postdata = {
      filePath: document.path,
      fileName: document.name
    }
    PIS.post(API_URL.staffLeave + `/delete-document?id=${document?.id}`, postdata)
      .then(response => {
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        if (response.data.type === SUCCESS) {
          getStaffLeaveData();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const removeMedicalExpensesFile = (document) => {
    setShowDeleteMedicalExpensesDocumentConfirmationModal(false);
    let postdata = {
      filePath: document.path,
      fileName: document.name
    }
    PIS.post(API_URL.healthExpenses + `/delete-document?id=${document?.id}`, postdata)
      .then(response => {
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        if (response.data.type === SUCCESS) {
          getMedicalExpensesDetails();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const removeDepartmentalPunishmentFile = (document) => {
    setShowDeleteDepartmentalPunishmentDocumentConfirmationModal(false);
    let postdata = {
      filePath: document.path,
      fileName: document.name
    }
    PIS.post(API_URL.departmentalPunishment + `/delete-document?id=${document?.id}`, postdata)
      .then(response => {
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        if (response.data.type === SUCCESS) {
          getDepartmentalPunishmentDetails();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  useEffect(() => {
    if (leaveFiles.length) {
      var postdata = new FormData();
      postdata.append("staffId", staffId)
      for (let i = 0; i < leaveFiles.length; i++) {
        postdata.append("file", leaveFiles[i]);
      }
      uploadLeaveFiles(postdata);
    }
  }, [leaveFiles]);

  const uploadLeaveFiles = postdata => {
    PIS.post(API_URL.staffLeave + `/upload-documents?id=${leaveId}`, postdata)
      .then(response => {
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        setLeaveId('');
        getStaffLeaveData();
        setLeaveFiles([]);
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
        setLeaveFiles([]);
      });
  };

  const uploadMedicalExpensesFiles = postdata => {
    PIS.post(API_URL.healthExpenses + `/upload-documents?id=${medicalExpensesId}`, postdata)
      .then(response => {
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        setMedicalExpensesId('');
        setMedicalExpensesFiles(null);
        getMedicalExpensesDetails();
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
        setMedicalExpensesFiles(null);
      });
  };

  useEffect(() => {
    if (medicalExpensesFiles?.length) {
      var postdata = new FormData();
      postdata.append("staffId", staffId);
      for (let i = 0; i < medicalExpensesFiles?.length; i++) {
        postdata.append("file", medicalExpensesFiles[i]);
      }
      uploadMedicalExpensesFiles(postdata);
    }
  }, [medicalExpensesFiles]);

  const getLeaveDocumentsList = (documents, id) => {
    var documentsList = [];
    for (let i = 0; i < documents.length; i++) {
      documentsList.push(
        <Grid key={i}>
          <Tooltip title={documents[i]?.name} placement="top" arrow>
            <a
              href={documents[i].absolutePath}
              target="_blank"
              className={`pr-2`}
            >{documents[i].name}
            </a>
          </Tooltip>
          {AppMisc.isRoleStaff() && <Tooltip title="चयनित कागजात हटाउनुहोस्।" placement="top">
            <IconButton
              onClick={() => handleLeaveDocumentDelete(documents[i], id)}
              color="secondary"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>}
        </Grid>
      )
    }
    return (
      <>
        {documentsList}
      </>
    )
  };

  const uploadDepartmentalPunishmentFiles = postdata => {
    PIS.post(API_URL.departmentalPunishment + `/upload-documents?id=${departmentalPunishmentId}`, postdata)
      .then(response => {
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        setDepartmentalPunishmentId('');
        setDepartmentalPunishmentFiles(null);
        getDepartmentalPunishmentDetails();
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
        setMedicalExpensesFiles(null);
      });
  };

  useEffect(() => {
    if (departmentalPunishmentFiles?.length) {
      var postdata = new FormData();
      postdata.append("staffId", staffId);
      for (let i = 0; i < departmentalPunishmentFiles?.length; i++) {
        postdata.append("file", departmentalPunishmentFiles[i]);
      }
      uploadDepartmentalPunishmentFiles(postdata);
    }
  }, [departmentalPunishmentFiles]);

  const getMedicalExpensesDocumentsList = (documents, id) => {
    var documentsList = [];
    for (let i = 0; i < documents.length; i++) {
      documentsList.push(
        <Grid key={i} >
          <Tooltip title={documents[i]?.name} placement="top" arrow>
            <a
              href={documents[i].absolutePath}
              target="_blank"
              className={`pr-2`}
            >{documents[i].name}
            </a>
          </Tooltip>

          {AppMisc.isRoleStaff() && <Tooltip title="चयनित कागजात हटाउनुहोस्।" placement="top">
            <IconButton
              onClick={() => handleMedicalExpensesDocumentDelete(documents[i], id)}
              color="secondary"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>}
        </Grid>
      )
    }
    return (
      <>
        {documentsList}
      </>
    )
  };

  const getDepartmentalPunishmentDocumentsList = (documents, id) => {
    var documentsList = [];
    for (let i = 0; i < documents.length; i++) {
      documentsList.push(
        <Grid item key={i}>
          <Tooltip title={documents[i]?.name} placement="top" arrow>
            <a
              href={documents[i].absolutePath}
              target="_blank"
              className={`pr-2`}
            >{documents[i].name}
            </a>
          </Tooltip>
          {AppMisc.isRoleStaff() && <Tooltip title="चयनित कागजात हटाउनुहोस्।" placement="top">
            <IconButton
              onClick={() => handleDepartmentalPunishmentDocumentDelete(documents[i], id)}
              color="secondary"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>}
        </Grid>
      )
    }
    return (
      <>
        {documentsList}
      </>
    )
  };

  function handleFileChange(e) {
    let files = e.target.files;
    let individualFileSizeValid = true;
    let fileSize = 0;
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        fileSize += files[i].size;
        if (files[i].size > 10 * 1024 * 1024) {
          individualFileSizeValid = false;
          AddAlertMessage({ type: ERROR, message: FILE_SIZE_EXCEED });
          return false;
        }
      }
      let allFilesSizeValid = fileSize < (30 * 1024 * 1024);
      if (individualFileSizeValid && allFilesSizeValid) {
        setFiles(e.target.files);
      } else {
        AddAlertMessage({ type: ERROR, message: ALL_FILES_SIZE_EXCEED });
      }
    }
  }

  const getStaffData = () => {
    PIS.get(API_URL.staff + "/" + staffId).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        jsondata.data["personalInfo"]["dateOfBirthInBS"] = jsondata.data["personalInfo"]["dateOfBirth"] && DateUtils.getDateFromMilliseconds(jsondata.data["personalInfo"]["dateOfBirth"]);
        if (jsondata.data.appointedServiceInfo) {
          jsondata.data.appointedServiceInfo["applicationDateInBS"] = jsondata.data.appointedServiceInfo["applicationDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.appointedServiceInfo["applicationDate"]);
          jsondata.data.appointedServiceInfo["decisionDateInBS"] = jsondata.data.appointedServiceInfo["decisionDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.appointedServiceInfo["decisionDate"]);
          jsondata.data.appointedServiceInfo["officeAttendanceDateInBS"] = jsondata.data.appointedServiceInfo["officeAttendanceDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.appointedServiceInfo["officeAttendanceDate"]);
        }
        if (jsondata.data.currentServiceInfo) {
          jsondata.data.currentServiceInfo["applicationDateInBS"] = jsondata.data.currentServiceInfo["applicationDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.currentServiceInfo["applicationDate"]);
          jsondata.data.currentServiceInfo["decisionDateInBS"] = jsondata.data.currentServiceInfo["decisionDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.currentServiceInfo["decisionDate"]);
          jsondata.data.currentServiceInfo["officeAttendanceDateInBS"] = jsondata.data.currentServiceInfo["officeAttendanceDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.currentServiceInfo["officeAttendanceDate"]);
          jsondata.data.currentServiceInfo["appointmentToCurrentPostDateInBS"] = jsondata.data.currentServiceInfo["appointmentToCurrentPostDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.currentServiceInfo["appointmentToCurrentPostDate"]);
          jsondata.data.currentServiceInfo["propertyDetailsSubmissionDateInBS"] = jsondata.data.currentServiceInfo["propertyDetailsSubmissionDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.currentServiceInfo["propertyDetailsSubmissionDate"]);
          jsondata.data.currentServiceInfo["performanceAppraisalSubmissionDateInBS"] = jsondata.data.currentServiceInfo["performanceAppraisalSubmissionDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.currentServiceInfo["performanceAppraisalSubmissionDate"]);
        }
        if (jsondata.data.othersInfo) {
          jsondata.data.othersInfo["accumulationFundNumberReleaseDateInBS"] = jsondata.data.othersInfo["accumulationFundNumberReleaseDate"] && DateUtils.getDateFromMilliseconds(jsondata.data.othersInfo["accumulationFundNumberReleaseDate"]);
        }
        if (jsondata.data.retirementDetail) {
          // TODO: sandeep - upgrade the react-nepali-calender library with dates upto 100 years from now and implement estimated date bs.
          jsondata.data.retirementDetail["dateInBS"] = jsondata.data.retirementDetail["date"] && DateUtils.getDateFromMilliseconds(jsondata.data.retirementDetail["date"]);
          jsondata.data.retirementDetail["estimatedDate"] = jsondata.data.retirementDetail["estimatedDate"] && new Date(jsondata.data.retirementDetail["estimatedDate"]).toLocaleDateString();
        }
        setStaffData(jsondata.data);
      }
    })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  };

  const handleFamilyDetailsUpdate = () => {
    setEditFamilyDetails(false);
    getStaffData();
  }

  const handleRetirementDetailsUpdate = () => {
    setEditRetirementDetails(false);
    getStaffData();
  }

  const removeFile = document => {
    setShowDeleteDocumentConfirmationModal(false);
    let postdata = {
      staffId: staffId,
      filePath: document.path,
      originalFileName: document.name
    }
    PIS.post(API_URL.staff + "/delete-document", postdata)
      .then(response => {
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        if (response.data.type === SUCCESS) {
          getStaffData();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const uploadFiles = postdata => {
    PIS.post(API_URL.staff + "/upload-documents", postdata)
      .then(response => {
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        getStaffData();
        setFiles([]);
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
        setFiles([]);
      });
  };

  useEffect(() => {
    getDepartments();
    getSubDepartments();
    getAllCategoriesList();
    getEducationalQualificationList();
    getAllPostList();
  }, []);

  useEffect(() => {
    !props.staffData ? getStaffData() : setStaffData(props.staffData);
  }, [props.staffData])

  const getAllPostList = () => {
    PIS.get(API_URL.post)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setPostOptions((jsondata.data || []).map(item => {
            return { value: item.id, label: item.name }
          }));
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const getEducationalQualificationList = () => {
    PIS.get(API_URL.academicDegree)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setEducationalQualificationList((jsondata.data || []).map(item => {
            return { value: item.id, label: item.name }
          }));
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG })
      })
  }

  const getAllCategoriesList = () => {
    PIS.get(API_URL.category)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setCategoryOptions((jsondata.data || []).map(item => {
            return { value: item.id, label: item.category }
          }))
        }
        else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  useEffect(() => {
    getStaffLeaveData();
  }, []);

  const onLeaveDetailsSubmit = (data) => {
    data.staffId = staffId;
    data.id = modalDefaultValue?.id;
    PIS.post(API_URL.staffLeave, data)
      .then(response => {
        if (response.data.type === SUCCESS) {
          setAddStaffLeave(false);
          AddAlertMessage({
            type: response.data.type,
            message: response.data.message
          });
          getStaffLeaveData();
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  useEffect(() => {
    getMedicalExpensesDetails();
    getDepartmentalPunishmentDetails();
  }, [])

  const getMedicalExpensesDetails = () => {
    PIS.get(API_URL.healthExpenses + `?staffId=${staffId}`).then((response) => {
      if (response.data.type === SUCCESS) {
        setMedicalExpensesDetails(response.data.data);
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const getDepartmentalPunishmentDetails = () => {
    PIS.get(API_URL.departmentalPunishment + `?staffId=${staffId}`).then((response) => {
      if (response.data.type === SUCCESS) {
        setDepartmentalPunishmentDetails(response.data.data);
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const onMedicalExpensesDetailsSubmit = data => {
    data.staffId = staffId;
    data.id = medicalExpensesDetailsDefaultValue?.id;
    PIS.post(API_URL.healthExpenses, data).then((response) => {
      if (response.data.type === SUCCESS) {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        getMedicalExpensesDetails();
        setEditStaffMedicalExpenses(false);
      }
      else {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const onDepartmentalPunishmentDetailsSubmit = data => {
    data.staffId = staffId;
    data.id = departmentalPunishmentDetailsDefaultValue?.id;
    PIS.post(API_URL.departmentalPunishment, data).then((response) => {
      if (response.data.type === SUCCESS) {
        AddAlertMessage({
          type: response.data.type,
          message: response.data.message
        });
        getDepartmentalPunishmentDetails();
        setEditDepartmentalPunishmentDetails(false);
      }
      else {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const getStaffLeaveData = () => {
    PIS.get(API_URL.staffLeave + `/?staffId=${staffId}`).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        setStaffLeaveDetails(jsondata.data);
        setStaffImmediateLeaveDetails(jsondata.data.filter((item) => item.leaveType === "IMMEDIATE_LEAVE"));
        setStaffFestivalLeaveDetails(jsondata.data.filter((item) => item.leaveType === "FESTIVAL_LEAVE"));
        setStaffHomeLeaveDetails(jsondata.data.filter((item) => item.leaveType === "HOME_LEAVE"));
        setStaffSickLeaveDetails(jsondata.data.filter((item) => item.leaveType === "SICK_LEAVE"));
        setStaffPregnancyLeaveDetails(jsondata.data.filter((item) => item.leaveType === "PREGNANCY_LEAVE"));
        setStaffUnpaidPregnancyLeaveDetails(jsondata.data.filter((item) => item.leaveType === "UNPAID_PREGNANCY_LEAVE"));
        setStaffPregnancyCareLeaveDetails(jsondata.data.filter((item) => item.leaveType === "PREGNANCY_CARE_LEAVE"));
        setStaffDeathRitualLeaveDetails(jsondata.data.filter((item) => item.leaveType === "DEATH_RITUAL_LEAVE"));
        setStaffStudyLeaveDetails(jsondata.data.filter((item) => item.leaveType === "STUDY_LEAVE"));
        setStaffCommonAbnormalLeaveDetails(jsondata.data.filter((item) => item.leaveType === "COMMON_ABNORMAL_LEAVE"));
        setStaffStudyAbnormalLeaveDetails(jsondata.data.filter((item) => item.leaveType === "STUDY_ABNORMAL_LEAVE"));
        setStaffInsteadLeaveDetails(jsondata.data.filter((item) => item.leaveType === "INSTEAD_LEAVE"));
        setStaffAdditionalServiceLeaveDetails(jsondata.data.filter((item) => item.leaveType === "ADDITIONAL_SERVICE_LEAVE"));
        setStaffServingInternationalOrganizationLeaveDetails(jsondata.data.filter((item) => item.leaveType === "SERVING_INTERNATIONAL_ORGANIZATION_LEAVE"));
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  };

  const getSubDepartments = () => {
    PIS.get(API_URL.subDepartment)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setAllSubDepartmentList(jsondata.data || [])
        }
        else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message })
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      });
  }

  const getDepartments = () => {
    PIS.get(API_URL.department)
      .then(response => {
        let jsondata = response.data;
        if (jsondata.type === SUCCESS) {
          setDepartmentList(jsondata.data?.map(item => { return { id: item.id, name: item.name } }) || []);
        } else {
          AddAlertMessage({ type: jsondata.type, message: jsondata.message });
        }
      }).catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  }

  const handleStaffLeaveDetailsEdit = (id) => {
    PIS.get(API_URL.staffLeave + "/" + id).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        setModalDefaultValue(jsondata.data);
        setAddStaffLeave(true);
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const handleMedicalExpensesDetailsEdit = (id) => {
    PIS.get(API_URL.healthExpenses + "/" + id).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        setMedicalExpensesDetailsDefaultValue(jsondata.data);
        setEditStaffMedicalExpenses(true);
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const handleDepartmentalPunishmentDetailsEdit = (id) => {
    PIS.get(API_URL.departmentalPunishment + "/" + id).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        setDepartmentalPunishmentDetailsDefaultValue(jsondata.data);
        setEditDepartmentalPunishmentDetails(true);
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const handleStaffLeaveDetailsDelete = (id) => {
    setShowDeleteStaffLeaveConfirmationModal(false);
    PIS.delete(API_URL.staffLeave + "/" + id).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        AddAlertMessage({ type: SUCCESS, message: response.data.message });
        setLeaveId('');
        getStaffLeaveData();
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const handleStaffMedicalExpensesDetailsDelete = (id) => {
    setShowDeleteStaffMedicalExpensesConfirmationModal(false);
    PIS.delete(API_URL.healthExpenses + "/" + id).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        AddAlertMessage({ type: SUCCESS, message: response.data.message });
        setMedicalExpensesId('');
        getMedicalExpensesDetails();
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  const handleDepartmentalPunishmentDetailsDelete = (id) => {
    setShowDeleteDepartmentalPunishmentConfirmationModal(false)
    PIS.delete(API_URL.departmentalPunishment + "/" + id).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        AddAlertMessage({ type: SUCCESS, message: response.data.message });
        setDepartmentalPunishmentId('');
        getDepartmentalPunishmentDetails();
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    });
  }

  useEffect(() => {
    staffData && setStaffDataLoading(false);
  }, [staffData]);

  useEffect(() => {
    if (files.length) {
      var postdata = new FormData();
      postdata.append("staffId", staffId);
      for (let i = 0; i < files.length; i++) {
        postdata.append("file", files[i]);
      }
      uploadFiles(postdata);
    }
  }, [files]);

  const handleStaffMedicalExpensesAddCancel = () => {
    setEditStaffMedicalExpenses(false)
  }

  const handleStaffMedicalExpensesAdd = () => {
    setEditStaffMedicalExpenses(!editStaffMedicalExpenses);
  }

  const handleDepartmentalPunishmentAddCancel = () => {
    setEditDepartmentalPunishmentDetails(false);
  }

  const handleDepartmentalPunishmentAdd = () => {
    setEditDepartmentalPunishmentDetails(!editDepartmentalPunishmentDetails);
  }

  const handlePersonalDetailsEdit = () => {
    setEditPersonalDetails(!editPersonalDetails);
  }

  const handlePersonalDetailsEditCancel = () => {
    setEditPersonalDetails(false);
  }

  const handleFamilyDetailsEdit = () => {
    setEditFamilyDetails(!editFamilyDetails)
  }

  const handleFamilyDetailsEditCancel = () => {
    setEditFamilyDetails(false);
  }

  const handleCurrentServiceDetailsEditCancel = () => {
    setEditCurrentServiceDetails(false);
  }

  const handleRetirementDetailsEditCancel = () => {
    setEditRetirementDetails(false);
  }

  const handleAddressDetailsEdit = () => {
    setEditAddressDetails(!editFamilyDetails)
  }

  const handleRetirementDetailsEdit = () => {
    setEditRetirementDetails(!editRetirementDetails)
  }

  const handleAppointedServiceDetailsEdit = () => {
    setEditAppointedServiceDetails(!editAppointedServiceDetails)
  }

  const handleCurrentServiceDetailsEdit = () => {
    setEditCurrentServiceDetails(!editAddressDetails);
  }

  const handleOtherDetailsEdit = () => {
    setEditOtherDetails(!editOtherDetails);
  }

  const handleEducationalDetailsEdit = () => {
    setEditEducationalDetails(!editEducationalDetails);
  }

  const handleAwardAndTestimonialDetailsEdit = () => {
    setEditAwardAndTestimonialDetails(!editAwardAndTestimonialDetails);
  }

  const handleAddressDetailsEditCancel = () => {
    setEditAddressDetails(false);
  }

  const handleAppointedServiceDetailsEditCancel = () => {
    setEditAppointedServiceDetails(false);
  }

  const handleOtherDetailsEditCancel = () => {
    setEditOtherDetails(false);
  }

  const handleEducationalDetailsEditCancel = () => {
    setEditEducationalDetails(false);
  }

  const handleAwardAndTestimonialDetailsEditCancel = () => {
    setEditAwardAndTestimonialDetails(false);
  }

  const handleDocumentDelete = document => {
    setShowDeleteDocumentConfirmationModal(true);
    setDocumentToDelete(document);
  }

  const handlePersonalDetailsUpdate = data => {
    setEditPersonalDetails(false);
    getStaffData();
  }

  const handleAddressDetailsUpdate = data => {
    setEditAddressDetails(false);
    getStaffData();
  }

  const handleAppointedServiceDetailsUpdate = () => {
    setEditAppointedServiceDetails(false);
    getStaffData();
  }

  const handleCurrentServiceDetailsUpdate = () => {
    setEditCurrentServiceDetails(false);
    getStaffData();
  }

  const handleOtherDetailsUpdate = () => {
    setEditOtherDetails(false);
    getStaffData();
  }

  const handleEducationalDetailsUpdate = () => {
    setEditEducationalDetails(false);
    getStaffData();
  }

  const handleAwardAndTestimonialDetailsUpdate = () => {
    setEditAwardAndTestimonialDetails(false);
    getStaffData();
  }

  const handleStaffLeaveDetailsAddCancel = () => {
    setAddStaffLeave(false);
  }

  const handleStaffLeaveDetailsAdd = () => {
    setModalDefaultValue([]);
    setAddStaffLeave(!addStaffLeave);
  }

  const getDocumentsList = (documents) => {
    var documentsList = [];
    for (let i = 0; i < documents.length; i++) {
      documentsList.push(
        <Grid item xs={3} className={classes.documentRow} key={i}>
          <Tooltip title={documents[i].name} placement="top" arrow>
            <Link
              href={documents[i].absolutePath}
              target="_blank"
              className={classes.documentLink}
            >
              <FilePreviewerThumbnail
                file={{
                  url: documents[i].absolutePath
                }}
              />
            </Link>
          </Tooltip>
          <Tooltip title="चयनित कागजात हटाउनुहोस्।" placement="top">
            <IconButton
              onClick={() => { handleDocumentDelete(documents[i]) }}
              className={classes.deleteDocumentIcon}
              color="secondary"
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Grid>
      )
    }
    return (
      <>
        {documentsList}
      </>
    )
  };

  return (
    <>
      <Container maxWidth="xl" className={classes.root} disableGutters>
        <Box ref={printComponentRef} className={classes.staffDetailsContainer}>
          <Box ref={ref}>
            <Paper className={classes.paper}>
              <Box className={classes.paperHeader}>
                <Typography variant="h6">श्री {staffData.personalInfo?.fullNameInNepali} {staffData.personalInfo?.staffCodeNumber && "(" + staffData.personalInfo.staffCodeNumber + ")"} को व्यक्तिगत विवरण {!staffData.personalInfo?.formerEmployee ? "(कार्यरत)" : "(भूतपूर्व)"}</Typography>
                <Box className={classes.paperActionButtons}>
                  {editPersonalDetails ?
                    (
                      <IconButton aria-label="cancel" onClick={handlePersonalDetailsEditCancel}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton className="print-none" aria-label="edit" onClick={handlePersonalDetailsEdit}>
                          <CreateIcon />
                        </IconButton>

                        <ReactToPrint
                          trigger={
                            () => <IconButton aria-label="print" className="print-none">
                              <Print />
                            </IconButton>
                          }
                          content={() => printComponentRef.current}
                        />
                      </>
                    )
                  }
                </Box>
              </Box>
              {
                staffDataLoading ?
                  (
                    <EmptyContainer>
                      <Spinner />
                    </EmptyContainer>
                  ) : (
                    editPersonalDetails ?
                      (
                        <AddPersonalDetails
                          onPersonalDetailsUpdate={(data) => handlePersonalDetailsUpdate(data)}
                          personalDetails={staffData?.personalInfo || {}}
                          showAddPersonalDetailModal={editPersonalDetails}
                          closeAddPersonalDetailModal={handlePersonalDetailsEditCancel}
                        />
                      ) : (
                        <StaffPersonalDetails personalDetails={staffData.personalInfo} getStaffData={getStaffData} />
                      )
                  )
              }
            </Paper>
            <Paper className={classes.paper}>
              <Box className={classes.paperHeader}>
                <Typography variant="h6">पारिवारिक विवरण</Typography>
                <Box className={classes.paperActionButtons}>
                  {editFamilyDetails ?
                    (
                      <IconButton aria-label="cancel" onClick={handleFamilyDetailsEditCancel}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton className="print-none" aria-label="edit" onClick={handleFamilyDetailsEdit}>
                          <CreateIcon />
                        </IconButton>
                      </>
                    )
                  }
                </Box>
              </Box>
              {
                staffDataLoading ?
                  (
                    <EmptyContainer>
                      <Spinner />
                    </EmptyContainer>
                  ) : (
                    editFamilyDetails ?
                      (
                        <AddFamilyDetails
                          onFamilyDetailsUpdate={handleFamilyDetailsUpdate}
                          familyDetails={staffData?.familyInfo || {}}
                        />
                      ) : (
                        <StaffFamilyDetails familyDetails={staffData.familyInfo} />
                      )
                  )
              }
            </Paper>
            <Paper className={classes.paper}>
              <Box className={classes.paperHeader}>
                <Typography variant="h6"> ठेगाना बारे विवरण</Typography>
                <Box className={classes.paperActionButtons}>
                  {editAddressDetails ?
                    (
                      <IconButton aria-label="cancel" onClick={handleAddressDetailsEditCancel}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton className="print-none" aria-label="edit" onClick={handleAddressDetailsEdit}>
                          <CreateIcon />
                        </IconButton>
                      </>
                    )
                  }
                </Box>
              </Box>
              {
                staffDataLoading ?
                  (
                    <EmptyContainer>
                      <Spinner />
                    </EmptyContainer>
                  ) : (
                    editAddressDetails ?
                      (
                        <AddAddressDetails
                          onAddressDetailsUpdate={handleAddressDetailsUpdate}
                          addressDetails={staffData?.addressDetail || {}}
                        />
                      ) : (
                        <StaffAddressDetails addressDetails={staffData.addressDetail} />
                      )
                  )
              }
            </Paper>
            <Paper className={classes.paper}>
              <Box className={classes.paperHeader}>
                <Typography variant="h6">नियूक्ती सेवा विवरण</Typography>
                <Box className={classes.paperActionButtons}>
                  {editAppointedServiceDetails ?
                    (
                      <IconButton aria-label="cancel" onClick={handleAppointedServiceDetailsEditCancel}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton className="print-none" aria-label="edit" onClick={handleAppointedServiceDetailsEdit}>
                          <CreateIcon />
                        </IconButton>
                      </>
                    )
                  }
                </Box>
              </Box>
              {
                staffDataLoading ?
                  (
                    <EmptyContainer>
                      <Spinner />
                    </EmptyContainer>
                  ) : (
                    editAppointedServiceDetails ?
                      (
                        <AddAppointedServiceDetails
                          onAppointedServiceDetailsUpdate={handleAppointedServiceDetailsUpdate}
                          appointedServiceDetails={staffData?.appointedServiceInfo || {}}
                        />
                      ) : (
                        <StaffAppointedServiceDetails
                          appointedServiceDetails={staffData.appointedServiceInfo}
                          categoryList={categoryOptions}
                          postOptions={postOptions}
                        />
                      )
                  )
              }
            </Paper>
            <Paper className={classes.paper}>
              <Box className={classes.paperHeader}>
                <Typography variant="h6">हालको सेवा विवरण</Typography>
                <Box className={classes.paperActionButtons}>
                  {editCurrentServiceDetails ?
                    (
                      <IconButton aria-label="cancel" onClick={handleCurrentServiceDetailsEditCancel}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton className="print-none" aria-label="edit" onClick={handleCurrentServiceDetailsEdit}>
                          <CreateIcon />
                        </IconButton>
                      </>
                    )
                  }
                </Box>
              </Box>
              {
                staffDataLoading ?
                  (
                    <EmptyContainer>
                      <Spinner />
                    </EmptyContainer>
                  ) : (
                    editCurrentServiceDetails ?
                      (
                        <AddCurrentServiceDetails
                          onCurrentServiceDetailsUpdate={handleCurrentServiceDetailsUpdate}
                          currentServiceDetails={staffData?.currentServiceInfo || {}}
                          subDepartmentList={allSubDepartmentList}
                        />
                      ) : (
                        <StaffCurrentServiceDetails
                          currentServiceDetails={staffData.currentServiceInfo}
                          departmentList={departmentList}
                          subDepartmentList={allSubDepartmentList}
                          categoryList={categoryOptions}
                          postOptions={postOptions}
                        />
                      )
                  )
              }
            </Paper>
            <Paper className={classes.paper}>
              <Box className={classes.paperHeader}>
                <Typography variant="h6">अबकाश अग्रसुचन विवरण</Typography>
                <Box className={classes.paperActionButtons}>
                  {editRetirementDetails ?
                    (
                      <IconButton aria-label="cancel" onClick={handleRetirementDetailsEditCancel}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton className="print-none" aria-label="edit" onClick={handleRetirementDetailsEdit}>
                          <CreateIcon />
                        </IconButton>
                      </>
                    )
                  }
                </Box>
              </Box>
              {
                staffDataLoading ?
                  (
                    <EmptyContainer>
                      <Spinner />
                    </EmptyContainer>
                  ) : (
                    editRetirementDetails ?
                      (<AddRetirementDetails
                        retirementDetails={staffData?.retirementDetail || {}}
                        onRetirementDetailsUpdate={handleRetirementDetailsUpdate} />) :
                      (<StaffRetirementDetails retirementDetails={staffData.retirementDetail} />)
                  )
              }
            </Paper>
            <Paper className={classes.paper}>
              <Box className={classes.paperHeader}>
                <Typography variant="h6">अन्य सामान्य विवरण</Typography>
                <Box className={classes.paperActionButtons}>
                  {editOtherDetails ?
                    (
                      <IconButton aria-label="cancel" onClick={handleOtherDetailsEditCancel}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton className="print-none" aria-label="edit" onClick={handleOtherDetailsEdit}>
                          <CreateIcon />
                        </IconButton>
                      </>
                    )
                  }
                </Box>
              </Box>
              {
                staffDataLoading ?
                  (
                    <EmptyContainer>
                      <Spinner />
                    </EmptyContainer>
                  ) : (
                    editOtherDetails ?
                      (
                        <AddOtherDetails
                          onOtherDetailsUpdate={handleOtherDetailsUpdate}
                          otherDetails={staffData?.othersInfo || {}}
                        />
                      ) : (
                        <OtherDetails otherDetails={staffData.othersInfo} />
                      )
                  )
              }
            </Paper>
            <Paper className={classes.paper}>
              <Box className={classes.paperHeader}>
                <Typography variant="h6">शैक्षिक विवरण</Typography>
                <Box className={classes.paperActionButtons}>
                  {editEducationalDetails ?
                    (
                      <IconButton aria-label="cancel" onClick={handleEducationalDetailsEditCancel}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton className="print-none" aria-label="edit" onClick={handleEducationalDetailsEdit}>
                          <CreateIcon />
                        </IconButton>
                      </>
                    )
                  }
                </Box>
              </Box>
              {
                staffDataLoading ?
                  (
                    <EmptyContainer>
                      <Spinner />
                    </EmptyContainer>
                  ) : (
                    editEducationalDetails ?
                      (
                        <AddEducationalDetails
                          onEducationalDetailsUpdate={handleEducationalDetailsUpdate}
                          educationalDetails={staffData?.educationalDetailList}
                          educationalQualificationList={educationalQualificationList}
                        />
                      ) : (
                        <EducationalDetails
                          educationalDetails={staffData.educationalDetailList || []}
                          educationalQualificationList={educationalQualificationList}
                        />
                      )
                  )
              }
            </Paper>
            <Paper className={classes.paper}>
              <Box className={classes.paperHeader}>
                <Typography variant="h6">विभूषण, प्रशंसा पत्र र पुरस्कारको विवरण</Typography>
                <Box className={classes.paperActionButtons}>
                  {editAwardAndTestimonialDetails ?
                    (
                      <IconButton aria-label="cancel" onClick={handleAwardAndTestimonialDetailsEditCancel}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      <>
                        <IconButton className="print-none" aria-label="edit" onClick={handleAwardAndTestimonialDetailsEdit}>
                          <CreateIcon />
                        </IconButton>
                      </>
                    )
                  }
                </Box>
              </Box>
              {
                staffDataLoading ?
                  (
                    <EmptyContainer>
                      <Spinner />
                    </EmptyContainer>
                  ) : (
                    editAwardAndTestimonialDetails ?
                      (
                        <AddAwardAndTestimonialDetails
                          onAwardAndTestimonialDetailsUpdate={handleAwardAndTestimonialDetailsUpdate}
                          awardAndTestimonialDetails={staffData.awardAndTestimonialDetailList}
                        />
                      ) : (
                        <AwardAndTestimonialDetail awardAndTestimonialDetails={staffData?.awardAndTestimonialDetailList || []} />
                      )
                  )
              }
            </Paper>
            <Paper className={classes.paper}>
              <Box className={classes.paperHeader}>
                <Typography variant="h6">बिदा विवरण</Typography>
                {addStaffLeave ?
                  (
                    <IconButton aria-label="cancel" onClick={handleStaffLeaveDetailsAddCancel}>
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    AppMisc.isRoleStaff() && <IconButton className="print-none" aria-label="edit" onClick={handleStaffLeaveDetailsAdd}>
                      <AddIcon />
                    </IconButton>
                  )
                }
              </Box>
              {
                addStaffLeave ? (<AddStaffLeaveDetailsModal
                  showAddStaffLeaveDetailModal={addStaffLeave}
                  closeAddStaffLeaveDetailModal={handleStaffLeaveDetailsAddCancel}
                  onLeaveDetailsSubmit={onLeaveDetailsSubmit}
                  modalDefaultValue={modalDefaultValue}
                />) : (
                  <>
                    {staffLeaveDetails?.length === 0 ? (
                      <EmptyContainer>
                        <Typography variant="h6">{NO_RECORDS_FOUND}</Typography>
                      </EmptyContainer>
                    ) : (
                      <>
                        {staffImmediateLeaveDetails?.length !== 0 && <ImmediateLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} immediateLeaveInfo={staffImmediateLeaveDetails} />}
                        {staffFestivalLeaveDetails?.length !== 0 && <FestivalLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} festivalLeaveInfo={staffFestivalLeaveDetails} />}
                        {staffHomeLeaveDetails?.length !== 0 && <HomeLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} homeLeaveInfo={staffHomeLeaveDetails} />}
                        {staffSickLeaveDetails?.length !== 0 && <SickLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} sickLeaveInfo={staffSickLeaveDetails} />}
                        {staffPregnancyLeaveDetails?.length !== 0 && <PregnancyLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} pregnancyLeaveInfo={staffPregnancyLeaveDetails} />}
                        {staffUnpaidPregnancyLeaveDetails?.length !== 0 && <UnpaidPregnancyLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} unpaidPregnancyLeaveInfo={staffUnpaidPregnancyLeaveDetails} />}
                        {staffPregnancyCareLeaveDetails?.length !== 0 && <PregnancyCareLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} pregnancyCareLeaveInfo={staffPregnancyCareLeaveDetails} />}
                        {staffDeathRitualLeaveDetails?.length !== 0 && <DeathRitualLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} deathRitualLeaveInfo={staffDeathRitualLeaveDetails} />}
                        {staffStudyLeaveDetails?.length !== 0 && <StudyLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} studyLeaveInfo={staffStudyLeaveDetails} />}
                        {staffCommonAbnormalLeaveDetails?.length !== 0 && <CommonAbnormalLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} commonAbnormalLeaveInfo={staffCommonAbnormalLeaveDetails} />}
                        {staffStudyAbnormalLeaveDetails?.length !== 0 && <StudyAbnormalLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} studyAbnormalLeaveInfo={staffStudyAbnormalLeaveDetails} />}
                        {staffInsteadLeaveDetails?.length !== 0 && <InsteadLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} insteadLeaveInfo={staffInsteadLeaveDetails} />}
                        {staffAdditionalServiceLeaveDetails?.length !== 0 && <AdditionalServiceLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} additionalServiceLeaveInfo={staffAdditionalServiceLeaveDetails} />}
                        {staffServingInternationalOrganizationLeaveDetails?.length !== 0 && <ServingInternationalOrganizationLeaveDetails getLeaveRowDeleteId={getLeaveRowDeleteId} getLeaveDocumentsList={getLeaveDocumentsList} handleStaffLeaveDetailsEdit={handleStaffLeaveDetailsEdit} getLeaveRowId={setLeaveRowId} handleLeaveFileChange={handleLeaveFileChange} servingInternationalOrganizationLeaveInfo={staffServingInternationalOrganizationLeaveDetails} />}
                      </>)}
                  </>
                )
              }
            </Paper>
            <Paper className={`${classes.paper} print-none`} >
              <Box className={classes.paperHeader}>
                <Typography variant="h6">उपचार खर्चको विवरण</Typography>
                <Box className={classes.paperActionButtons}>
                  {editStaffMedicalExpenses ?
                    (
                      <IconButton aria-label="cancel" onClick={handleStaffMedicalExpensesAddCancel}>
                        <CloseIcon />
                      </IconButton>
                    ) : (
                      AppMisc.isRoleStaff() && <IconButton className="print-none" aria-label="edit" onClick={handleStaffMedicalExpensesAdd}>
                        <AddIcon />
                      </IconButton>
                    )
                  }
                </Box>
              </Box>
              {
                editStaffMedicalExpenses ? (
                  <AddStaffMedicalExpensesDetails
                    modalDefaultValue={medicalExpensesDetailsDefaultValue || []}
                    onMedicalExpensesDetailsSubmit={onMedicalExpensesDetailsSubmit}
                  />
                ) : (
                  <StaffMedicalExpensesDetails
                    handleMedicalExpensesDetailsEdit={handleMedicalExpensesDetailsEdit}
                    medicalExpensesDetails={medicalExpensesDetails}
                    getMedicalExpensesRowDeleteId={getMedicalExpensesRowDeleteId}
                    getMedicalExpensesRowId={setMedicalExpensesRowId}
                    handleMedicalExpensesFileChange={handleMedicalExpensesFileChange}
                    getMedicalExpensesDocumentsList={getMedicalExpensesDocumentsList}
                  />
                )
              }
            </Paper>
            <Paper className={`${classes.paper} print-none`} >
              <Box className={classes.paperHeader}>
                <Typography variant="h6">विभागीय सजायको विवरण</Typography>
                <Box className={classes.paperActionButtons}>
                  {
                    editDepartmentalPunishmentDetails ?
                      (
                        <IconButton aria-label="cancel" onClick={handleDepartmentalPunishmentAddCancel}>
                          <CloseIcon />
                        </IconButton>
                      ) : (
                        AppMisc.isRoleStaff() && <IconButton className="print-none" aria-label="edit" onClick={handleDepartmentalPunishmentAdd}>
                          <AddIcon />
                        </IconButton>
                      )
                  }
                </Box>
              </Box>
              {
                editDepartmentalPunishmentDetails ? (
                  <AddDepartmentalPunishmentDetails
                    modalDefaultValue={departmentalPunishmentDetailsDefaultValue || []}
                    onDepartmentalPunishmentDetailsSubmit={onDepartmentalPunishmentDetailsSubmit}
                  />
                ) : (
                  <DepartmentalPunishmentDetails
                    handleDepartmentalPunishmentDetailsEdit={handleDepartmentalPunishmentDetailsEdit}
                    departmentalPunishmentDetails={departmentalPunishmentDetails}
                    getDepartmentalPunishmentRowDeleteId={getDepartmentalPunishmentRowDeleteId}
                    getDepartmentalPunishmentRowId={setDepartmentalPunishmentRowId}
                    handleDepartmentalPunishmentFileChange={handleDepartmentalPunishmentFileChange}
                    getDepartmentalPunishmentDocumentsList={getDepartmentalPunishmentDocumentsList}
                  />
                )
              }
            </Paper>
            <Paper className={`${classes.paper} print-none`} >
              <Box className={classes.paperHeader}>
                <Typography variant="h6">कर्मचारी परिचयपत्र</Typography>
                {AppMisc.isRoleStaff() && <Box className={classes.paperActionButtons}>
                  <>
                    <ReactToPrint
                      trigger={
                        () => <IconButton aria-label="print" className="print-none">
                          <Print />
                        </IconButton>
                      }
                      content={() => printCardRef.current}
                    />
                  </>
                </Box>}
              </Box>
              <Box className={classes.cardContainer} >
                <Box ref={printCardRef}>
                  {
                    staffDataLoading ?
                      (
                        <EmptyContainer>
                          <Spinner />
                        </EmptyContainer>
                      ) : (
                        <Card staffData={staffData} postOptions={postOptions} categoryOptions={categoryOptions} />
                      )
                  }
                </Box>
              </Box>
            </Paper>
            <Paper className={`print-none ${classes.paper}`}>
              <Box className={classes.paperHeader}>
                <Box display="flex" alignItems="center">
                  <Typography variant="h6">कागजातहरू</Typography>
                  <Tooltip title="तपाईं यहाँ कर्मचारी सँग सम्बन्धित कागजातहरू अपलोड गर्न सक्नुहुनेछ। कृपया फाईल चयन गर्दा 10MB भन्दा कम साइजको फाइल चयन गर्नहुन अनुरोध छ। तपाईं एक पटकमा अधिकतम 30MB अपलोड गर्न सक्नुहुनेछ र एकल फाईल 10MB भन्दा बढि हुनुहुन्न।" placement="top" arrow>
                    <Help className={classes.helpIcon} />
                  </Tooltip>
                </Box>
                <Box className={classes.paperActionButtons}>
                  <input id="uploadStaffDocuments" className={classes.hiddenInput} type="file" accept=".pdf,.doc,.ppt,.pptx,.txt,.docx,.xlsx,.xls,.jpg,.jpeg,.png,.gif,.bmp,.ico" onClick={(e) => { e.currentTarget.value = null }} onChange={(event) => { handleFileChange(event) }} multiple />
                  <label htmlFor="uploadStaffDocuments">
                    <Tooltip title="सम्बन्धित कागजातहरु अपलोड गर्नुहोस्।" arrow placement="top">
                      <IconButton aria-label="upload files" component="span">
                        <PublishIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </label>
                </Box>
              </Box>
              <Box p={2}>
                {
                  staffData &&
                    staffData.documents ? (
                    staffData.documents.length ? (
                      <Grid className="print-none" container alignItems="flex-start" spacing={2}>
                        {getDocumentsList(staffData.documents)}
                      </Grid>
                    ) : (
                      <EmptyContainer>
                        <Typography className={classes.documentsNotUploadedMsg}>माफ गर्नुहोस्, तपाईंले सम्बन्धित कुनै पनि कागजातहरू अपलोड गर्नुभएको छैन। कृपया आवश्यक कागजातहरू अपलोड गर्नुहोस्।</Typography>
                      </EmptyContainer>
                    )
                  ) : (
                    <EmptyContainer>
                      <Typography className={classes.documentsNotUploadedMsg}>माफ गर्नुहोस्, तपाईंले सम्बन्धित कुनै पनि कागजातहरू अपलोड गर्नुभएको छैन। कृपया आवश्यक कागजातहरू अपलोड गर्नुहोस्।</Typography>
                    </EmptyContainer>
                  )
                }
              </Box>
            </Paper>
          </Box>
        </Box>
        <CustomModal
          title="फाइल हटाउनुहोस्।"
          onModalSubmit={() => { removeFile(documentToDelete) }}
          showModal={showDeleteDocumentConfirmationModal}
          onModalClose={() => setShowDeleteDocumentConfirmationModal(false)}
          submitButtonText="हटाउनुहोस्"
        >
          <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ चयनित फाइल हटाउन चाहनुहुन्छ?</Typography>
          <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
        </CustomModal>
        <CustomModal
          title="फाइल हटाउनुहोस्।"
          onModalSubmit={() => { removeLeaveFile(leaveDocumentToDelete) }}
          showModal={showDeleteLeaveDocumentConfirmationModal}
          onModalClose={() => setShowDeleteLeaveDocumentConfirmationModal(false)}
          submitButtonText="हटाउनुहोस्"
        >
          <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ चयनित फाइल हटाउन चाहनुहुन्छ?</Typography>
          <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
        </CustomModal>
        <CustomModal
          title="फाइल हटाउनुहोस्।"
          onModalSubmit={() => { removeMedicalExpensesFile(medicalExpensesDocumentToDelete) }}
          showModal={showDeleteMedicalExpensesDocumentConfirmationModal}
          onModalClose={() => setShowDeleteMedicalExpensesDocumentConfirmationModal(false)}
          submitButtonText="हटाउनुहोस्"
        >
          <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ चयनित फाइल हटाउन चाहनुहुन्छ?</Typography>
          <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
        </CustomModal>
        <CustomModal
          title="फाइल हटाउनुहोस्।"
          onModalSubmit={() => { removeDepartmentalPunishmentFile(departmentalPunishmentDocumentToDelete) }}
          showModal={showDeleteDepartmentalPunishmentDocumentConfirmationModal}
          onModalClose={() => setShowDeleteDepartmentalPunishmentDocumentConfirmationModal(false)}
          submitButtonText="हटाउनुहोस्"
        >
          <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ चयनित फाइल हटाउन चाहनुहुन्छ?</Typography>
          <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
        </CustomModal>
        <CustomModal
          title="कर्मचारी बिदा विवरण हटाउनुहोस्।"
          onModalSubmit={() => { handleStaffLeaveDetailsDelete(leaveId) }}
          showModal={showDeleteStaffLeaveConfirmationModal}
          onModalClose={() => setShowDeleteStaffLeaveConfirmationModal(false)}
          submitButtonText="हटाउनुहोस्"
        >
          <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ चयनित कर्मचारी बिदा विवरण हटाउन चाहनुहुन्छ?</Typography>
          <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
        </CustomModal>
        <CustomModal
          title="कर्मचारी उपचार खर्च विवरण हटाउनुहोस्।"
          onModalSubmit={() => { handleStaffMedicalExpensesDetailsDelete(medicalExpensesId) }}
          showModal={showDeleteStaffMedicalExpensesConfirmationModal}
          onModalClose={() => setShowDeleteStaffMedicalExpensesConfirmationModal(false)}
          submitButtonText="हटाउनुहोस्"
        >
          <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ चयनित कर्मचारी उपचार खर्च विवरण हटाउन चाहनुहुन्छ?</Typography>
          <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
        </CustomModal>
        <CustomModal
          title="कर्मचारी विभागीय सजाय विवरण हटाउनुहोस्।"
          onModalSubmit={() => { handleDepartmentalPunishmentDetailsDelete(departmentalPunishmentId) }}
          showModal={showDeleteDepartmentalPunishmentConfirmationModal}
          onModalClose={() => setShowDeleteDepartmentalPunishmentConfirmationModal(false)}
          submitButtonText="हटाउनुहोस्"
        >
          <Typography>के तपाइँ निश्चित हुनुहुन्छ कि तपाइँ चयनित कर्मचारी विभागीय सजाय विवरण हटाउन चाहनुहुन्छ?</Typography>
          <Typography color="error" variant="caption" component="em">* {AFTER_DELETE_YOU_CAN_NOT_UNDONE_MSG}</Typography>
        </CustomModal>
      </Container>
    </>
  );
}

export default forwardRef(StaffDetails)
