import { Box, Button, Container, IconButton, Tooltip, Typography } from "@material-ui/core";
import { Add, GetApp, Print, Publish } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { getUserInfo } from "../../../action/userInfo";
import PIS, { API_URL } from "../../../api/api";
import AddAlertMessage from "../../../components/alert/Alert";
import { AppUtils } from "../../../utils/appUtils";
import { DEFAULT_ROW_SIZE, ERROR, GENDER_OPTIONS, SOMETHING_WENT_WRONG, SUCCESS } from "../../../utils/constants";
import { DateUtils } from "../../../utils/dateUtils";
import AddPersonalDetails from "../components/staff/add-personal-details/AddPersonalDetailsModal";
import StaffsList from "./helpers/StaffsList";
import styles from "./style";

export default function Staffs() {
  const classes = styles();
  const dispatch = useDispatch();
  const [staffsList, setStaffsList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState('');
  const [size, setSize] = useState();
  const [rowSize, setRowSize] = useState(DEFAULT_ROW_SIZE);
  const [showAddPersonalDetailModal, setShowAddPersonalDetailModal] = useState(false);
  const [modalDefaultValues, setModalDefaultValues] = useState({});
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector(state => state.userInfo);
  const filter = AppUtils.getUrlParam("filter");
  const formerEmployee = AppUtils.getUrlParam("formerEmployee");

  useEffect(() => {
    !userInfo && dispatch(getUserInfo())
  }, [])

  const printComponentRef = useRef();
  let history = useHistory();

  useEffect(() => {
    pageNumber && getStaffs();
  }, [pageNumber, searchParam, rowSize])

  function getPaginationData(currentPage) {
    setPageNumber(currentPage);
  }

  function getSearchData(searchFilter) {
    setSearchParam(searchFilter);
  }

  function getRowSize(rowSize) {
    setRowSize(rowSize);
  }

  function getGenderLabel(gender) {
    return gender ? GENDER_OPTIONS.find(obj => obj.value === gender).label : "-";
  }

  const headers = [
    { label: "नाम", key: "personalInfo.fullNameInNepali" },
    { label: "लिङ्ग", key: "personalInfo.gender" },
    { label: "जन्म मिति (बि.सं.)", key: "personalInfo.dateOfBirth" },
    { label: "नागरिकता नं.", key: "personalInfo.citizenshipNumber" },
    { label: "राष्ट्रीय परिचय पत्र नं.", key: "personalInfo.nationalIdentityCardNumber" },
    { label: "पति/पत्नीको नाम", key: "familyInfo.husbandOrWifeNameInNepali" },
    { label: "पिताको नाम", key: "familyInfo.fatherNameInNepali" },
    { label: "आमाको नाम", key: "familyInfo.motherNameInNepali" },
    { label: "छोरी संख्या", key: "familyInfo.daughterCount" },
    { label: "छोरा संख्या", key: "familyInfo.sonCount" },
    { label: "प्रदेश", key: "addressDetail.permanentAddressProvince" },
    { label: "जिल्ला", key: "addressDetail.permanentAddressDistrict" },
    { label: "गाउँ/नगरपालिका", key: "addressDetail.permanentAddressPalikaName" },
    { label: "वडा नं.", key: "addressDetail.permanentAddressWardNumber" },
    { label: "गाँउ/टोल", key: "addressDetail.permanentAddressGaunOrTole" },
    { label: "घर नं", key: "addressDetail.permanentAddressHomeNumber" },
    { label: "टेलीफोन नं", key: "addressDetail.permanentAddressPhoneNumber" },
    { label: "अन्य संपर्क विवरण", key: "addressDetail.permanentAddressOthersContactDetail" },
    { label: "माथिल्लो निकाय (नियूक्ती सेवा)", key: "appointedServiceInfo.upperBody" },
    { label: "जिल्ला", key: "appointedServiceInfo.district" },
    { label: "कार्यालय", key: "appointedServiceInfo.office" },
    { label: "सेवा परिमाण", key: "appointedServiceInfo.serviceVolume" },
    { label: "सेवाको किसिम", key: "appointedServiceInfo.serviceType" },
    { label: "श्रेणी/तह", key: "appointedServiceInfo.categoryAndLabel" },
    { label: "पद", key: "appointedServiceInfo.post" },
    { label: "निर्णय मिति", key: "appointedServiceInfo.decisionDate" },
    { label: "कार्यालयमा हाजिरी मिति", key: "appointedServiceInfo.officeAttendanceDate" },
    { label: "माथिल्लो निकाय (हालको सेवा)", key: "currentServiceInfo.upperBody" },
    { label: "जिल्ला", key: "currentServiceInfo.district" },
    { label: "कार्यालय", key: "currentServiceInfo.office" },
    { label: "सेवा परिमाण", key: "currentServiceInfo.serviceVolume" },
    { label: "सेवाको किसिम", key: "currentServiceInfo.serviceType" },
    { label: "श्रेणी/तह", key: "currentServiceInfo.categoryAndLabel" },
    { label: "पद", key: "currentServiceInfo.post" },
    { label: "निर्णय मिति", key: "currentServiceInfo.decisionDate" },
    { label: "कार्यालयमा हाजिरी मिति", key: "currentServiceInfo.officeAttendanceDate" },
    { label: "अवकाशको स्थिति", key: "retirementDetail.status" },
    { label: "अवकाश", key: "retirementDetail.type" },
    { label: "अवकाशको मिति", key: "retirementDetail.date" },
    { label: "अनुमानित अवकाशको मिति", key: "retirementDetail.estimatedDate" },
    { label: "क.सं. कोष परिचय पत्र नं.", key: "othersInfo.citizenInvestmentFundNumber" },
    { label: "राहदानी नं:", key: "othersInfo.passportNumber" },
    { label: "सवारी चालक अनुमती नं.", key: "othersInfo.drivingLicenceNumber" },
    { label: "अशक्त विवरण", key: "othersInfo.differentlyAbledDescription" },
    { label: "मोबाईल नम्बर", key: "othersInfo.mobileNumber" }

  ]

  const getStaffs = () => {
    setIsLoading(true);
    let url = API_URL.staff + `?pageNumber=${pageNumber}&rowSize=${rowSize}`
    if (searchParam) {
      url += `&searchParam=${searchParam}`
    }
    if (filter) {
      url += `&filter=${filter}`
    }
    if (formerEmployee) {
      url += `&formerEmployee=${formerEmployee}`
    }
    PIS.get(url).then(response => {
      let jsondata = response.data;
      for (let i = 0; i < jsondata.objectList.length; i++) {
        jsondata.objectList[i].personalInfo.gender = getGenderLabel(jsondata.objectList[i].personalInfo.gender);
        jsondata.objectList[i].personalInfo.dateOfBirth = jsondata.objectList[i].personalInfo.dateOfBirth !== 0 ? DateUtils.getDateFromMilliseconds(jsondata.objectList[i].personalInfo.dateOfBirth) : "-";
        jsondata.objectList[i].personalInfo.citizenshipNumber = jsondata.objectList[i].personalInfo.citizenshipNumber || "-";
        jsondata.objectList[i].personalInfo.nationalIdentityCardNumber = jsondata.objectList[i].personalInfo.nationalIdentityCardNumber || "-";

        jsondata.objectList[i].familyInfo.husbandOrWifeNameInNepali = jsondata.objectList[i].familyInfo.husbandOrWifeNameInNepali || "-;"
        jsondata.objectList[i].familyInfo.fatherNameInNepali = jsondata.objectList[i].familyInfo.fatherNameInNepali || "-";
        jsondata.objectList[i].familyInfo.motherNameInNepali = jsondata.objectList[i].familyInfo.motherNameInNepali || "-";
        jsondata.objectList[i].familyInfo.daughterCount = jsondata.objectList[i].familyInfo.daughterCount || "-";
        jsondata.objectList[i].familyInfo.sonCount = jsondata.objectList[i].familyInfo.sonCount || "-";

        jsondata.objectList[i].addressDetail.permanentAddressProvince = jsondata.objectList[i].addressDetail.permanentAddressProvince || "-";
        jsondata.objectList[i].addressDetail.permanentAddressDistrict = jsondata.objectList[i].addressDetail.permanentAddressDistrict || "-";
        jsondata.objectList[i].addressDetail.permanentAddressPalikaName = jsondata.objectList[i].addressDetail.permanentAddressPalikaName || "-";
        jsondata.objectList[i].addressDetail.permanentAddressWardNumber = jsondata.objectList[i].addressDetail.permanentAddressWardNumber || "-";
        jsondata.objectList[i].addressDetail.permanentAddressGaunOrTole = jsondata.objectList[i].addressDetail.permanentAddressGaunOrTole || "-";
        jsondata.objectList[i].addressDetail.permanentAddressHomeNumber = jsondata.objectList[i].addressDetail.permanentAddressHomeNumber || "-";
        jsondata.objectList[i].addressDetail.permanentAddressPhoneNumber = jsondata.objectList[i].addressDetail.permanentAddressPhoneNumber || "-";
        jsondata.objectList[i].addressDetail.permanentAddressOthersContactDetail = jsondata.objectList[i].addressDetail.permanentAddressOthersContactDetail || "-";

        jsondata.objectList[i].appointedServiceInfo.upperBody = jsondata.objectList[i].appointedServiceInfo.upperBody || "-";
        jsondata.objectList[i].appointedServiceInfo.district = jsondata.objectList[i].appointedServiceInfo.district || "-";
        jsondata.objectList[i].appointedServiceInfo.office = jsondata.objectList[i].appointedServiceInfo.office || "-";
        jsondata.objectList[i].appointedServiceInfo.serviceVolume = jsondata.objectList[i].appointedServiceInfo.serviceVolume || "-";
        jsondata.objectList[i].appointedServiceInfo.serviceType = jsondata.objectList[i].appointedServiceInfo.serviceType || "-";
        jsondata.objectList[i].appointedServiceInfo.categoryAndLabel = jsondata.objectList[i].appointedServiceInfo.categoryAndLabel || "-";
        jsondata.objectList[i].appointedServiceInfo.post = jsondata.objectList[i].appointedServiceInfo.post || "-";
        jsondata.objectList[i].appointedServiceInfo.decisionDate = jsondata.objectList[i].appointedServiceInfo.decisionDate !== 0 ? DateUtils.getDateFromMilliseconds(jsondata.objectList[i].appointedServiceInfo.decisionDate) : "-";
        jsondata.objectList[i].appointedServiceInfo.officeAttendanceDate = jsondata.objectList[i].appointedServiceInfo.officeAttendanceDate !== 0 ? DateUtils.getDateFromMilliseconds(jsondata.objectList[i].appointedServiceInfo.officeAttendanceDate) : "-";

        jsondata.objectList[i].currentServiceInfo.upperBody = jsondata.objectList[i].currentServiceInfo.upperBody || "-";
        jsondata.objectList[i].currentServiceInfo.district = jsondata.objectList[i].currentServiceInfo.district || "-";
        jsondata.objectList[i].currentServiceInfo.office = jsondata.objectList[i].currentServiceInfo.office || "-";
        jsondata.objectList[i].currentServiceInfo.serviceVolume = jsondata.objectList[i].currentServiceInfo.serviceVolume || "-";
        jsondata.objectList[i].currentServiceInfo.serviceType = jsondata.objectList[i].currentServiceInfo.serviceType || "-";
        jsondata.objectList[i].currentServiceInfo.categoryAndLabel = jsondata.objectList[i].currentServiceInfo.categoryAndLabel || "-";
        jsondata.objectList[i].currentServiceInfo.post = jsondata.objectList[i].currentServiceInfo.post || "-";
        jsondata.objectList[i].currentServiceInfo.decisionDate = jsondata.objectList[i].currentServiceInfo.decisionDate !== 0 ? DateUtils.getDateFromMilliseconds(jsondata.objectList[i].currentServiceInfo.decisionDate) : "-";
        jsondata.objectList[i].currentServiceInfo.officeAttendanceDate = jsondata.objectList[i].currentServiceInfo.officeAttendanceDate ? DateUtils.getDateFromMilliseconds(jsondata.objectList[i].currentServiceInfo.officeAttendanceDate) : "-";

        jsondata.objectList[i].retirementDetail.status = jsondata.objectList[i].retirementDetail.status || "-";
        jsondata.objectList[i].retirementDetail.type = jsondata.objectList[i].retirementDetail.type || "-";
        jsondata.objectList[i].retirementDetail.date = jsondata.objectList[i].retirementDetail.date ? DateUtils.getDateFromMilliseconds(jsondata.objectList[i].retirementDetail.date) : "-";
        jsondata.objectList[i].retirementDetail.estimatedDate = jsondata.objectList[i].retirementDetail.estimatedDate ? DateUtils.getDateFromMilliseconds(jsondata.objectList[i].retirementDetail.estimatedDate) : "-";
        jsondata.objectList[i].othersInfo.citizenInvestmentFundNumber = jsondata.objectList[i].othersInfo.citizenInvestmentFundNumber || "-";
        jsondata.objectList[i].othersInfo.passportNumber = jsondata.objectList[i].othersInfo.passportNumber || "-";
        jsondata.objectList[i].othersInfo.drivingLicenceNumber = jsondata.objectList[i].othersInfo.drivingLicenceNumber || "-";
        jsondata.objectList[i].othersInfo.differentlyAbledDescription = jsondata.objectList[i].othersInfo.differentlyAbledDescription || "-";
        jsondata.objectList[i].othersInfo.mobileNumber = jsondata.objectList[i].othersInfo.mobileNumber || "-";
        setStaffsList(jsondata.objectList);
        setSize(jsondata.size);
        setIsLoading(false);
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      setIsLoading(false);
    });
  }

  const closeAddPersonalDetailModal = () => {
    setShowAddPersonalDetailModal(false);
    setModalDefaultValues({});
  }

  const handleEditRow = id => {
    PIS.get(API_URL.staff + "/" + id).then(response => {
      let jsondata = response.data;
      if (jsondata.type === SUCCESS) {
        jsondata.data.personalInfo.id = id;
        setModalDefaultValues(jsondata.data.personalInfo);
        setShowAddPersonalDetailModal(true);
      } else {
        AddAlertMessage({ type: jsondata.type, message: jsondata.message })
      }
    }).catch(error => {
      AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
    })
  }

  const uploadFiles = excelFile => {
    const formData = new FormData();
    for (let i = 0; i < excelFile.length; i++) {
      formData.append("file", excelFile[i]);
    }
    formData.append("officeId", userInfo?.officeId)
    PIS.post(API_URL.importFromExcel, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
      .then(response => {
        AddAlertMessage({ type: response.data.type, message: response.data.message });
        if (response.data.type === SUCCESS) {
          getStaffs();
          setFile(null);
        }
      })
      .catch(error => {
        AddAlertMessage({ type: ERROR, message: SOMETHING_WENT_WRONG });
      })
  };

  useEffect(() => {
    file && userInfo?.officeId && uploadFiles(file);
  }, [file, userInfo]);

  function handleFileChange(e) {
    let files = e.target.files;
    let filesList = [];
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        filesList.push(files[i]);
      }
    }
    setFile(filesList);
  }

  return (
    <Container maxWidth="xl" className={classes.root} disableGutters>
      <Box my={3}>
        <AddPersonalDetails
          onPersonalDetailsUpdate={(id) => { history.push(`staff-details?id=${id}`) }}
          showAddPersonalDetailModal={showAddPersonalDetailModal}
          closeAddPersonalDetailModal={closeAddPersonalDetailModal}
          personalDetails={modalDefaultValues}
        />
      </Box>
      <Box className="border-bottom-heading" display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h6">कर्मचारीहरु</Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Box mx={2}>
            <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => setShowAddPersonalDetailModal(true)}>नयाँ कर्मचारी थप्नुहोस्</Button>
          </Box>
          <>
            <input id="uploadDocuments" hidden={true} type="file" accept=".xlsx,.xls" onChange={(event) => { handleFileChange(event) }} />
            <label htmlFor="uploadDocuments">
              <Tooltip title="एक्सेल मार्फत कर्मचारीहरू थप्नुहोस्" arrow placement="top">
                <IconButton aria-label="upload files" component="span">
                  <Publish fontSize="small" />
                </IconButton>
              </Tooltip>
            </label>
          </>
          <Tooltip title="Excel डाउनलोड गर्नुहोस्।" placement="top" arrow>
            <CSVLink
              data={staffsList}
              headers={headers}
              filename="PIS-कर्मचारीहरु.csv"
              uFEFF={false}
              target="_blank"
            >
              <IconButton>
                <GetApp />
              </IconButton>
            </CSVLink>
          </Tooltip>
          <ReactToPrint
            trigger={
              () => <IconButton aria-label="print" className="print-none">
                <Print />
              </IconButton>
            }
            content={() => printComponentRef.current}
          />
        </Box>
      </Box>
      <Box ref={printComponentRef}>
        <StaffsList isLoading={isLoading} size={size} rowSize={rowSize} functionPagination={getPaginationData} functionSearchParam={getSearchData} functionRowSize={getRowSize} getTableData={getStaffs} tableData={staffsList} showActionColumn={staffsList.length !== 0} editRow={handleEditRow} pageSize={pageNumber} />
      </Box>
    </Container>
  );
}
